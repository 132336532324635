import { SxProps, Theme } from '@mui/material';
import { EmotionCache } from '@emotion/cache';
import { ClientStyleContextData } from '~/utils/ClientStyleContext';

export const mergeSx = (currentSx: SxProps<Theme>, incomingSx?: SxProps<Theme>) => {
    if (!incomingSx) {
        return currentSx;
    }
    return [currentSx, ...(Array.isArray(incomingSx) ? incomingSx : [incomingSx])];
};

// This is used to remove the server side rendered styles and apply the client side styles
export const flushCacheAndRepplyStyles = (
    emotionCache: EmotionCache,
    clientStyleData: ClientStyleContextData,
): void => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
        // eslint-disable-next-line no-underscore-dangle
        (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData.reset();
};
